html,
body,
#root {
  margin: 0;
  padding: 0;
}
/* body {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
} */
/* html {
  width: 100vw;
  height: 100%;
  overflow: hidden;
} */
